.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  background: var(--c-white);
  color: var(--c-gray-very-dark);
  padding: var(--s-10);
}
.month {
  font-size: var(--fs-3);
  padding: var(--s-5);
}
.monthTitle {
  font-size: 200%;
  font-weight: 500;
  @media (min-width: 580px) {
      
    }
    @media (min-width: 780px) {
     font-size:150%; 
    }
    @media (min-width: 1000px){
      
    }
      @media (min-width: 1400px){
      font-size: var(--fs-6);
      }
}
.table {
}
.row {
}
.cell {
}
.unselectedDay {
  background: none;
  cursor: pointer;
  text-align: center;
  padding: var(--s-1);
  font-size: var(--fs-4);
  @media (min-width: 500px) {
  }
  @media (min-width: 780px) {
    font-size: 0.80vw;
    // padding: var(--s-1);
  }
  @media (min-width: 1000px) {
  }
    @media (min-width: 1400px){
    padding: var(--s-2);
    font-size: var(--fs-5);
    }
    @media(min-width: 1800px){
      font-size: var(--fs-6);
    }
}
.selectedDay {
  @extend .unselectedDay;
  background: var(--c-green);
  color: var(--c-white);
  cursor: pointer;
  text-align: center;
  /* border-radius: 100%; */
}
