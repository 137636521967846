.googleMap {
  width: 100%;
  height: var(--s-21);
  padding-bottom: var(--s-10);
  @media (min-width: 500px) {
    
    }
    @media (min-width: 780px) {
      // width: var(--s-22);
      height: 60vh;
      // height: var(--s-21);
      padding-bottom: 0;
    }
    @media (min-width: 1000){
      // width: 100vw;
    }
}
.pin {
  display: flex;
  align-items: center;
  color: var(--c-green);
  font-size: var(--fs-8);
}
.pinIcon {
  transform: translate(-50%, -100%);
}
.verticalRange {
  writing-mode: bt-lr; /* IE */
  -webkit-appearance: slider-vertical; /* WebKit */
  width: 8px;
  height: 175px;
  padding: 0 5px;
  direction: rtl;
}

.inputSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 95%;
  width: 100%;
  @media (min-width: 500px) {
    
  }
  @media (min-width: 780px) {
      max-width: 60%;
      min-width: 60%;
      height: 80%;
    }
    @media (min-width: 1000px){
    
    }
      @media (min-width: 1400px){
      }
}
.inputSectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: var(--c-gray-dark);
  color: var(--c-white);
  z-index: 10;
  width: 100%;
  height: 100%;
  position: relative;
  font-size: var(--fs-6);
  padding: var(--s-12);
  @media (min-width: 500px) {
    
  }
  @media (min-width: 780px) {
    font-size:initial;
    padding: var(--s-8);
    }
    @media (min-width: 1000px){
      min-height: 80vh;
    
    }
      @media (min-width: 1400px){
      padding:var(--s-10);
      min-height: 80vh;
      }
}

.inputSectionTitle {
  background: var(--c-orange);
  color: var(--c-black);
  width: 100%;
  margin: 0;
  padding-inline: var(--s-10);
  z-index: 1;
}
.directInputSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--s-10);
  width: 100%;
  padding-inline: var(--s-10);
}
.graphicalSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40vh;
  position: relative;
}
.depthGraphicalSection {
  @extend .graphicalSection;
  width: 300px;
  @media (min-width: 500px) {
    width: 400px;
  }
  @media (min-width: 780px) {
    width: 400px;
  }
  @media (min-width: 1000px){
    width:430px;
  }
    @media (min-width: 1400px){
    width: 700px;
    }
}
.interactiveSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.infoButton {
  position: absolute;
  font-size: var(--fs-8);
  top: 0;
  right: 0;
  @media (min-width: 500px) {
    
    }
    @media (min-width: 780px) {
      top: var(--s-8);
      left: var(--s-8);
      right:initial;
      font-size: var(--fs-7);
    }
    @media (min-width: 1000px){
    
    }
      @media (min-width: 1400px){
      font-size: var(--fs-8);
      }
}
.instructionText {
  margin-bottom: var(--s-10);
  @media (min-width: 500px) {
    
    }
    @media (min-width: 780px) {
    
      margin-top: var(--s-12);
      font-size: var(--fs-5);
      padding-inline: var(--s-10);
    }
    @media (min-width: 1000px){
      margin-top: var(--s-12);
    
    }
      @media (min-width: 1400px){
        font-size: var(--fs-7);
      
      }
}
.sliderRoot {
  position: absolute;
  bottom: 3px;
  height: 215px;
  color: var(--c-white);
  @media (min-width: 500px) {
    bottom: 3px;
    height: 295px;
    }
    @media (min-width: 780px) {
    bottom: 5px;
    height: 290px;
    }
    @media (min-width: 1000px){
    bottom: 5px;
    height: 315px;
    }
      @media (min-width: 1400px){
      height: 380px;
      }
}

.promptContainer {
  position: fixed;
  background: var(--c-orange-light);
  z-index: 50;
  padding: var(--s-12);
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-around;
  box-shadow: var(--shadow-1);
  font-size: var(--fs-6);
  top: 10%;
  right: 5%;
  left: 5%;
  bottom: 10%;
  // padding-top: var(--s-19);
  overflow: auto;
  @media (min-width: 500px) {
    // padding-top: var(--s-10);
    font-size: var(--fs-7);
    }
    @media (min-width: 780px) {
      bottom: 20%;
      top: 20%;
      right: 25%;
      left: 25%;
      padding: var(--s-12);
      // padding-top: var(--s-22);

    }
    @media (min-width: 1000px){
      // padding-top: var(--s-20);
      bottom: 24%;
      top: 24%;
      right: 30%;
      left: 30%;
    }
}

.closeInstructionsButton{
  position:fixed;
  bottom: 8%;
  z-index: 1100;
  background: var(--c-red);
  font-size: var(--fs-7);
  @media (min-width: 580px) {
    
    }
    @media (min-width: 780px) {
      bottom: 15%;
    }
    @media (min-width: 1000px){
      bottom: 20%;
    }
    @media (min-width: 1400px){
      bottom: 22%;
    }
}