@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap"); 
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&family=Quicksand:wght@500&display=swap');
@import url('https://css.gg/close.css');
@import url('https://css.gg/danger.css');
@import url('https://css.gg/chevron-down.css');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;300;400;500;700;900&display=swap');


/* configuration */
$ratio: 1.333;
$primary-unit: 1vw;
$scale-factor: 1;


@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }
  @if $exponent < 0 {
    @for $i from 1 through abs($exponent){
      $value: $value / $number;
    }
  }

  
  @return $value;
}

@function dimmension($number){
  @return pow($ratio, $number) * $scale-factor;
}

/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}

:root {
    /* units */
    --def-rounding: 5px;
    --light-rounding: 3px;
    /* Dimensions, no units */
    $d-0: 0;
    $d-1: pow($ratio , -7) * $scale-factor;
    $d-2: pow($ratio , -6);
    $d-3: pow($ratio , -5);
    $d-4: pow($ratio , -4);
    $d-5: pow($ratio , -3);
    $d-6: pow($ratio , -2);
    $d-7: pow($ratio , -1);
    $d-8: pow($ratio , 0);
    $d-9: pow($ratio , 1);
    $d-10: pow($ratio , 2);
    $d-11: pow($ratio , 3);
    $d-12: pow($ratio , 4);
    $d-13: pow($ratio , 5);
    $d-14: pow($ratio , 6);
    $d-15: pow($ratio , 7);
    $d-16: pow($ratio , 8);
    $d-17: pow($ratio , 9);
    $d-18: pow($ratio , 10);
    $d-19: pow($ratio , 11);
    $d-20: pow($ratio , 12);
    $d-21: pow($ratio , 13);
    $d-22: pow($ratio , 14);
    $d-23: pow($ratio , 15);
    $d-24: pow($ratio , 16);
    $d-100: 100;
    /* Sizes = dimensions plus primary unit */
    --s-0: 0;
    --s-1: #{dimmension(-7) * $primary-unit};
    --s-2: #{dimmension(-6) * $primary-unit};
    --s-3: #{dimmension(-5) * $primary-unit};
    --s-4: #{dimmension(-4) * $primary-unit};
    --s-5: #{dimmension(-3) * $primary-unit};
    --s-6: #{dimmension(-2) * $primary-unit};
    --s-7: #{dimmension(-1) * $primary-unit};
    --s-8: #{dimmension(0) * $primary-unit};
    --s-9: #{dimmension(1) * $primary-unit};
    --s-10: #{dimmension(2) * $primary-unit};
    --s-11: #{dimmension(3) * $primary-unit};
    --s-12: #{dimmension(4) * $primary-unit};
    --s-13: #{dimmension(5) * $primary-unit};
    --s-14: #{dimmension(6) * $primary-unit};
    --s-15: #{dimmension(7) * $primary-unit};
    --s-16: #{dimmension(8) * $primary-unit};
    --s-17: #{dimmension(9) * $primary-unit};
    --s-18: #{dimmension(10) * $primary-unit};
    --s-19: #{dimmension(11) * $primary-unit};
    --s-20: #{dimmension(12) * $primary-unit};
    --s-21: #{dimmension(13) * $primary-unit};
    --s-22: #{dimmension(14) * $primary-unit};
    --s-23: #{dimmension(15) * $primary-unit};
    --s-24: #{dimmension(16) * $primary-unit};
    --s-100: #{100+ $primary-unit};
    // negative sizes
    --s--1: #{dimmension(-7) * $primary-unit * -1};
    --s--2: #{dimmension(-6) * $primary-unit * -1};
    --s--3: #{dimmension(-5) * $primary-unit * -1};
    --s--4: #{dimmension(-4) * $primary-unit * -1};
    --s--5: #{dimmension(-3) * $primary-unit * -1};
    --s--6: #{dimmension(-2) * $primary-unit * -1};
    --s--7: #{dimmension(-1) * $primary-unit * -1};
    --s--8: #{dimmension(0) * $primary-unit * -1};
    --s--9: #{dimmension(1) * $primary-unit * -1};
    --s--10: #{dimmension(2) * $primary-unit * -1};
    --s--11: #{dimmension(3) * $primary-unit * -1};
    --s--12: #{dimmension(4) * $primary-unit * -1};
    --s--13: #{dimmension(5) * $primary-unit * -1};
    --s--14: #{dimmension(6) * $primary-unit * -1};
    --s--15: #{dimmension(7) * $primary-unit * -1};
    --s--16: #{dimmension(8) * $primary-unit * -1};
    --s--17: #{dimmension(9) * $primary-unit * -1};
    --s--18: #{dimmension(10) * $primary-unit * -1};
    --s--19: #{dimmension(11) * $primary-unit * -1};
    --s--20: #{dimmension(12) * $primary-unit * -1};
    --s--21: #{dimmension(13) * $primary-unit * -1};
    --s--22: #{dimmension(14) * $primary-unit * -1};
    --s--23: #{dimmension(15) * $primary-unit * -1};
    --s--24: #{dimmension(16) * $primary-unit * -1};
    --s--100: #{100+ $primary-unit * -1};
    /* Ratio sizes */
    --sr-0: 0%;
    --sr-1: unit(var(--s-18), %);
    --sr-2: unit(var(--s-22) , %);
    --sr-3: unit(var(--s-24) , %);
    --sr-4: 100%;
    /* Font sizes */
    --fs-0: 0;
    --fs-1: var(--s-6);
    --fs-2: var(--s-7);
    --fs-3: var(--s-8);
    --fs-4: var(--s-9);
    --fs-5: var(--s-10);
    --fs-6: var(--s-11);
    --fs-7: var(--s-12);
    --fs-8: var(--s-13);
    --fs-9: var(--s-14);

    // font types
    --font1: 'Heebo';
    --font2: 'Ubuntu';
    
    /* Colors */
    --c-green: #5AA144;
    --c-green-bright: #6Dcc4F;
    --c-green-light: #a2db91;
    --c-green-dark: #328419;
    --c-red: red;
    --c-white: white;
    --c-white-dark: #F4F4F4;
    --c-green-very-light: #D5DFD0;
    --c-black: #000000;
    --c-gray-very-dark: #232324;
    --c-gray-dark: #3D3c3E;
    --c-gray: #7A7979;
    --c-gray-light: #949394;
    --c-gray-medium: #C6C6C6;
    --c-gray-medium-light: #C4C4C4;
    --c-gray-very-light: #D8D8D8;
    --c-light-gray: #E3E3E3;
    --c-light-transparent:  rgb(255,255,255,0.8);
    --c-dark-transparent: rgb(0,0,0,0.9);
    --c-light-transparent-half: rgb(255,255,255,0.5);
    --c-blur: blur(1px);
    
    
    /* shadows */
    --shadow-1: rgb(0 0 0 / 30%) 1px 3px 4px 0px;
    --shadow-inner: inset 0px 2px 4px rgba(0, 0, 0, 0.3);
    --shadow-select: inset 0px 2px 2px rgba(0, 0, 0, 0.4);
    --shadow-1-surround: rgb(0 0 0 / 30%) 2px 0px 4px 4px;
    --shadow-bright: var(--c-green-bright) 2px 0px 4px 4px;


  //PDF GENERATOR SIZES, MUST BE STATIC UNITS
  --ss-1: 0.01in;
  --ss-2: 0.025in;
  --ss-3: 0.05in;
  --ss-35: 0.075in;
  --ss-375: 0.082in;
  --ss-4: 0.1in;
  --ss-5: 0.2in;
  --ss-6: 0.4in;
  --ss-7: 0.8in;
  --ss-8: 1in;
  --ss-9: 1.5in;
  --ss-975: 2.75in;
  --ss-10: 3in;

  }
  
  html{
    font-family: var(--font2);
  }
  // * {
  //   font-family: var(--font2);
  //   font-size: var(--fs-4);
  //   // color: var(--c-black);
  // }
  .s-buttonlike {
    cursor: pointer;
  }
:hover.s-buttonlike{
    color: var(--c-green-light);
}

  .s-disable-spinner-arrows::-webkit-inner-spin-button, 
  .s-disable-spinner-arrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }
  
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  
  input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }
  
  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    /* Hides the slider so custom styles can be added */
    background: transparent; 
    border-color: transparent;
    color: transparent;
  }
  
  html {
      font-size: var(--fs-4);
      color: var(--c-green);
  }
  div, 
  button, 
  select,
  input,
  textarea,
  section {
    border-radius: var(--def-rounding);
    // border: var(--c-black) solid 1px;
  }
  input,
  textarea,
  select {
      background: var(--c-white);
      border: none;
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
      border: none;
      border-radius: var(--def-rounding);
      background: var(--c-white);
  }

  div::-webkit-scrollbar-thumb{
      border-radius: 100%;
  }
  div::-webkit-scrollbar-track{
      border-radius: 100%;
  }
  
  

  button,
  select,
  textarea,
  input,
  a {
      cursor: pointer;
  }
  button,
  select,
  input,
  textarea {
      margin: var(--s-4);
      padding: var(--s-4);
  }
  select {
      padding-right: var(--s-11);
  }

  body {
     // background: linear-gradient(#949394, transparent);
      background: linear-gradient(#3D3c3E, #3D3c3E, #7A7979,#000000);
      background-attachment: fixed;
      padding: 0;
      margin: auto;
      width: 100%;
      height:100%;
  }
  #root {
    padding: 0;
    margin: 0;
    height: 100%;
  }
  div {
      margin: var(--s-5);
      padding: var(--s-5);
      box-sizing: border-box;
  } // ! ABOVE SETTING MESSES UP google-map-react 
  .remove-div-styling div {
    margin: 0;
    padding: 0;
  } 
  button {
      padding-inline: var(--s-10);
      border-radius: 100%;
  }
  h1 {
      // Primary Title of Software

    font-size: var(--fs-8);
    margin: var(--s-2);
    color: var(--c-green-dark);
    font-family: Ubuntu;
    letter-spacing: 0.13em;
    font-weight: 400;
    text-align: center;
  }
  
  h2 {
      font-size: var(--fs-7);
      margin: var(--s-4);
      font-family: 'Ubuntu';
  }
  h3 {
      font-size: var(--fs-6);
      margin: var(--s-3);
      font-family: 'Ubuntu';
  }
  h4 {
      font-size: var(--fs-5);
      margin: var(--s-2);
      font-family: 'Ubuntu';
      font-weight:400;
  }
  h5 {
    font-size: var(--fs-4);
    margin: var(--s-2);
    font-family: 'Heebo';
  }
  h6 {
    font-size: var(--fs-3);
    margin: var(--s-2);
  }

  p{
    margin: 0;
  }


 h6, button, select {
  font-family: var(--font1);
}
.EraseDefaultStylingOnChildren * {
  margin: 0;
  padding: 0;
}

// ! Styling React DateTime Picker

.rdtPicker {
  min-width: var(--s-14);
  padding: var(--s-3);
  margin-top: var(--s-1);
  z-index: 100;
  right: 0;
}